<template lang="html">
  <v-container mt-0 pt-0>
    <!-- <div class="ta-c mb-4">
      <img src="../assets/images/logo_plant3.png" alt="" class="logo-size" />
    </div> -->
    <v-layout row wrap justify-center>
      <div class="ta-c">
        <span class="title font-weight-bold">เข้าสู่ระบบ</span>
      </div>
    </v-layout>
    <v-layout row wrap justify-center>
      <v-flex md6 xs12 sm6 class="mt-2">
        <v-text-field
          label="เลขบัตรประชาชน/เลขทะเบียนนิติบุคคล"
          prepend-inner-icon="person"
          solo
          hide-details
          color="blue"
          :mask="mask"
          :rule="nameRules"
          v-model="card_id"
          @keyup.enter="login()"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center mt-3>
      <v-flex md2 xs6>
        <div class="ta-c">
          <v-btn
            color="primary"
            round
            block
            class="mx-0"
            :loading="loading"
            @click="login()"
            >เข้าสู่ระบบ</v-btn
          >
        </div>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center mt-1>
      <v-flex md12 xs12>
        <div class="ta-c">
          <span class="subtitle-1 font-weight-bold"
            >ท่านที่ยังไม่ได้ลงทะเบียน กรุณาลงทะเบียน<br />
          </span>
        </div>
      </v-flex>
      <v-flex md6 xs12>
        <div class="ta-c pointer indigo--text" @click="gotoRegister()">
          <v-icon class="mr-2 indigo--text">perm_identity</v-icon
          ><span class="subtitle-1 font-weight-bold underline pb-1"
            >ลงทะเบียนในนามบุคคล</span
          ><br />
        </div>
        <div
          class="ta-c mt-2 pointer indigo--text"
          @click="gotoRegisterCompany()"
        >
          <v-icon class="mr-2 indigo--text">business</v-icon
          ><span class="subtitle-1 font-weight-bold"
            ><span class="underline"
              >ลงทะเบียนกรณีหน่วยงานราชการ/องค์กร/บริษัท/อื่นๆ</span
            ></span
          >
        </div>
        <!-- <div
          class="ta-c mt-2 pointer indigo--text"
          @click="gotoRegisterStudentLoan()"
        >
          <v-icon class="mr-2 indigo--text">school</v-icon
          ><span class="title font-weight-bold"
            ><span class="underline">ลงทะเบียนกรณีผู้กู้ยืมกยศ.</span></span
          >
        </div> -->
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center>
      <v-flex xs12 md12 lg12>
        <div style="width:100%;text-align:center;">
          <v-btn
            flat
            round
            href="https://72m.forest.go.th/static/plant-knowledge/manual.jpg"
            target="_blank"
            ><v-icon class="mr-1">chrome_reader_mode</v-icon>ขั้นตอนการลงทะเบียน
            72 ล้านต้น พลิกฟื้นผืนป่า</v-btn
          >
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { Encode } from "@/services";
export default {
  data() {
    return {
      mask: "#############",
      nameRules: [(v) => !!v || "Name is required"],
      card_id: "",
      loading: false,
      company: "",
    };
  },
  methods: {
    gotoRegister() {
      this.$router.push("/register");
    },
    gotoRegisterCompany() {
      this.$router.push("/register/company");
    },
    gotoRegisterStudentLoan() {
      this.$router.push("/register/studentloan");
    },
    login() {
      this.loading = true;
      if (this.card_id !== "") {
        this.axios
          .post(process.env.VUE_APP_IPFLASK + "/login", {
            card_id: this.card_id,
          })
          .then((response) => {
            if (response.data !== "Not found id card") {
              // console.log(response.data);
              this.listInformation = response.data;
              // console.log(this.listInformation);

              this.card_id = this.listInformation[0].card_id;
              var cardid_encode = Encode.encode({ card_id: this.card_id });
              //check name or company
              if (this.listInformation[0].name !== "") {
                this.name = this.listInformation[0].name;
                // company_encode = Encode.encode({ company: "" });
                var status = Encode.encode({ status: "people" });
              } else {
                this.company = this.listInformation[0].companyname;
                // var company_encode = Encode.encode({ company: this.company });
                status = Encode.encode({ status: "company" });
              }

              // console.log(this.name);

              this.$cookies.set(
                "token",
                cardid_encode,
                "1d",
                "/",
                process.env.VUE_APP_WEB
              );
              this.$cookies.set(
                "status",
                status,
                "1d",
                "/",
                process.env.VUE_APP_WEB
              );

              if (this.company !== "") {
                this.name = this.company;
              } else {
                this.name = "คุณ" + this.name;
              }

              //console.log(this.name);
              this.$cookies.set(
                "name",
                this.name,
                "1d",
                "/",
                process.env.VUE_APP_WEB
              );

              this.$emit("getUsername", this.name);
              this.$router.push("/seeding");
              this.loading = false;
            } else {
              this.$swal(
                "ไม่พบเลขบัตรประชาชน/เลขทะเบียนนิติบุคคลในระบบ",
                "กรุณาตรวจสอบเลขบัตรประชาชน/เลขทะเบียนนิติบุคคล หรือลงทะเบียนอีกครั้ง",
                "error"
              );
              this.loading = false;
            }
          });
      } else {
        this.$swal(
          "กรุณากรอกเลขบัตรประชาชน/เลขทะเบียนนิติบุคคล เพื่อเข้าสู่ระบบ",
          "",
          "error"
        );
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 320px) {
  .logo-size {
    width: 70px;
    height: 70px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 600px) {
  .logo-size {
    width: 90px;
    height: 90px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 960px) {
  .logo-size {
    width: 90px;
    height: 90px;
  }
}

@media only screen and (min-width: 961px) {
  .logo-size {
    width: 100px;
    height: 100px;
  }
}
</style>
